import { useEffect, useState } from "react";
import styled, { css } from "styled-components/macro";
import Axios from "axios";
import { Box, BoxContent, BoxFooter, BoxHeader, BoxNav, BoxTitle } from "./Box";
import { SUCCESS_COLOR, THEME_COLOR_DARK, THEME_COLOR } from "../vars";
import { Check, Close, SkipioLogo, Icon } from "./Icons";
import Title from "./Title";
import Input from "./Input";
import Stack from "./Stack";
import Button from "./Button";
import { useFields } from "../hooks/use-fields";
import { meetsContrastGuidelines, darken } from "polished";
import Checkbox from "./Checkbox";
import { API_HOST } from "../env";
import { caseMethods, transformKeys } from "../api/case";

const axios = Axios.create({ baseURL: API_HOST + "/api/v2" });

axios.interceptors.request.use(function (config) {
  return {
    ...config,
    headers: {
      ...config.headers,
      "X-Skipio-Client": "split v1",
    },
    data: transformKeys(config.data, caseMethods.snake),
  };
});

axios.interceptors.response.use(function (response) {
  return {
    ...response,
    data: transformKeys(response.data, caseMethods.camel),
  };
});

type Link = {
  campaignId: string | null;
  leadFormBody: string;
  name: string;
  avatarUrl: string;
  logoUrl: string;
  bubbleText: string;
  widgetColor: string;
  user: {
    firstName: string;
    lastName: string;
    logoImageUrl: string | null;
    accountName: string;
  };
};

type Submission = {
  firstName: string;
  lastName: string;
  phoneMobile: string;
  email: string;
  companyName: string;
  body: string;
};

function maybe<T>(fn: () => T, backup: T) {
  try {
    return fn();
  } catch (_) {
    return backup;
  }
}

export default function Web2Txt({ formId }: { formId: string }) {
  const [link, setLink] = useState<Link>();

  useEffect(() => {
    axios({
      method: "get",
      url: `forms/${formId}`,
    }).then((res) => setLink(res.data.data));
  }, []);

  if (!link) {
    return null;
  }

  return (
    <Web2TxtInner
      link={link}
      onSubmit={async (value) => {
        const { body, ...contact } = value;
        await axios({
          method: "post",
          url: `capture_forms/${formId}`,
          data: { contact, message: { body } },
        });
      }}
    />
  );
}

export function Web2TxtInner({
  link,
  onSubmit,
}: {
  link: Link;
  onSubmit?: (submission: Submission) => Promise<void>;
}) {
  const [isShowing, setIsShowing] = useState(false);
  const [errors, setErrors] = useState<Record<string, any>>({});

  const [complete, setComplete] = useState(false);
  const { field, value } = useFields({
    initialValue: {
      firstName: "",
      lastName: "",
      phoneMobile: "",
      email: "",
      companyName: "",
      body: "",
      didAgree: false,
    },
  });

  async function save() {
    if (!onSubmit) return;
    setErrors({});

    try {
      await onSubmit(value);
      setComplete(true);
    } catch (e) {
      try {
        setErrors(e.response.data.errors);
      } catch (_) {
        throw e;
      }
    }
  }

  const avatarUrl = link.avatarUrl;
  const color = link.widgetColor || THEME_COLOR_DARK;
  const textColor = maybe(() => {
    const scores = meetsContrastGuidelines("#000", color);
    return scores.AAA;
  }, true)
    ? undefined
    : "#fff";

  return (
    <Reset>
      <Container>
        <Trigger>
          {!isShowing && (
            <Bubble>
              {Boolean(avatarUrl) && (
                <Avatar>
                  <div
                    style={
                      avatarUrl
                        ? { backgroundImage: `url(${avatarUrl})` }
                        : undefined
                    }
                  />
                </Avatar>
              )}
              {(link.bubbleText || link.leadFormBody)
                .split("\n")
                .map((str, index) => (
                  <div key={index}>{str}</div>
                ))}
            </Bubble>
          )}
          <OpenButton
            onClick={() => setIsShowing((s) => !s)}
            open={isShowing}
            color={color}
          >
            <SkipioLogo />
            <Close />
          </OpenButton>
        </Trigger>
        <OpeningBox elevated isShowing={isShowing}>
          {complete ? (
            <BoxContent
              dark
              style={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Stack>
                  <Check
                    style={{
                      color: SUCCESS_COLOR,
                      width: "48px",
                      height: "48px",
                    }}
                  />
                  <Title>
                    Thank you! We’ll text you shortly at the number you
                    provided.
                  </Title>
                </Stack>
              </div>
            </BoxContent>
          ) : (
            <>
              <BoxHeader
                style={{
                  background: color,
                  padding: "16px 0",
                }}
                stacked
              >
                <BoxTitle style={{ lineHeight: "1.5em", color: textColor }}>
                  <div>
                    {link.leadFormBody.split("\n").map((str, index) => (
                      <div key={index}>{str}</div>
                    ))}
                  </div>
                </BoxTitle>
              </BoxHeader>
              <BoxContent>
                <Stack>
                  <Input
                    label="First Name"
                    placeholder="First Name"
                    required
                    {...field("firstName")}
                    error={errors.firstName}
                  />
                  <Input
                    label="Last Name"
                    placeholder="Last Name"
                    {...field("lastName")}
                    error={errors.lastName}
                  />

                  <Input
                    label="Phone Number"
                    placeholder="Phone Number"
                    required
                    {...field("phoneMobile")}
                    error={errors.phoneMobile}
                  />
                  <Input
                    type="textarea"
                    label="Message"
                    placeholder=""
                    required
                    {...field("body")}
                    error={errors.phoneMobile}
                    rows={3}
                  />
                  <Checkbox {...field("didAgree")}>
                    Yes, I would like {link.user.accountName} to contact me via
                    text messaging.
                  </Checkbox>
                </Stack>
              </BoxContent>
              <BoxFooter stacked white style={{ height: "unset" }}>
                <BoxNav style={{ justifyContent: "center", height: "unset" }}>
                  <div style={{ flexDirection: "column", display: "flex" }}>
                    <Stack space={2}>
                      <Button onClick={save} disabled={!value.didAgree}>
                        Submit
                      </Button>
                      <div>
                        Powered by{" "}
                        <Anchor
                          href="https://skipio.com"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Skipio
                        </Anchor>
                      </div>
                    </Stack>
                  </div>
                </BoxNav>
              </BoxFooter>
            </>
          )}
        </OpeningBox>
      </Container>
    </Reset>
  );
}

const Reset = styled.div`
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", sans-serif;
  font-size: 14px;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  *:focus {
    outline: none;
  }
`;

const OpenButton = styled.button<{ open?: boolean; color: string }>`
  width: 60px;
  height: 60px;
  background: ${(p) =>
    p.open ? p.color : maybe(() => darken(0.1, p.color), p.color)};
  color: white;
  border: 0;
  border-radius: 100px;
  transition-duration: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 8px rgb(0, 0, 0, 0.2), 0px 4px 16px rgb(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transition-duration: 0s;
    transform: scale(0.98);
  }
  ${Icon} {
    position: absolute;
    width: 32px;
    height: 32px;
    transition-duration: 0.2s;
    ${(p) =>
      !p.open
        ? css`
            &:first-child {
              opacity: 1;
            }
            &:last-child {
              opacity: 0;
              transform: rotate(45deg);
            }
          `
        : css`
            &:first-child {
              opacity: 0;
            }
            &:last-child {
              opacity: 1;
              transform: rotate(0deg);
            }
          `}
  }
`;

const Bubble = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  right: 0;
  bottom: 100%;
  width: auto;
  background: white;
  padding: 10px 20px;
  box-shadow: 0px 1px 4px rgb(0, 0, 0, 0.1), 0px 4px 8px rgb(0, 0, 0, 0.05),
    0px 0px 0px 1px #00000008;
  margin-bottom: 10px;
  border-radius: 12px;
  max-width: 300px;
  font-size: 15px;
  line-height: 1.6em;
  font-weight: 500;
  color: #444;
`;

const Avatar = styled.div`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 1000px;
  position: relative;
  background-size: 100%;
  border: 0;
  overflow: hidden;
  z-index: 1000;
  background: white;
  left: -10px;
  &[title]:after {
    display: block;
    content: attr(title);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: 500;
    color: #676767;
  }
  & > div {
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
  }
`;

const Trigger = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 100;
`;

const Container = styled.div``;

const OpeningBox = styled(Box)<{ isShowing?: boolean }>`
  transition-duration: 0.2s;
  z-index: 1000;
  height: unset;
  width: 350px;
  border-radius: 7px;
  position: fixed;
  bottom: 90px;
  right: 20px;
  transform-origin: bottom right;
  ${(p) =>
    !p.isShowing &&
    css`
      transform: translate(20px, 10px) scale(0.95);
      opacity: 0;
      pointer-events: none;
    `}
  @media (max-width: 600px) {
    width: 100vw;
    max-width: unset;
    bottom: unset;
    left: 0;
    top: 0;
    height: calc(100% - 100px);
  }
`;

const Anchor = styled.a`
  color: ${THEME_COLOR_DARK};
  text-decoration: none;
  transition: color 0.2s ease-out;
  font-weight: 500;
  border-bottom: 1px dotted #ccc;
  &:hover,
  &:focus {
    border-bottom-color: ${THEME_COLOR};
    border-bottom-style: solid;
    color: ${THEME_COLOR_DARK};
    text-decoration: none;
  }
`;
